import PersonalDataModel from '@/shared/models/personalDataModel'
import GENDERS from '@/shared/enums/genders'
import SCHOOL_TERMS from '@/shared/enums/schoolTerms'
import YES_NO from '@/shared/enums/yesNo'
import CidRepository from '@/shared/http/repositories/socialProject/cid'
import DeficiencyTypeRepository from '@/shared/http/repositories/socialProject/deficiencyType'

const state = {
  personalData: new PersonalDataModel(),
  genders: Object.values(GENDERS),
  schoolTerms: Object.values(SCHOOL_TERMS),
  yesNo: Object.values(YES_NO),
  deficiencyTypes: [],
  age: null,
  companyId: '0FD1FF16-D422-4EFA-80C6-2B73400C039D'
}

const getters = {
  personalData: state => state.personalData,
  age: state => state.age,
  companyId: state => state.companyId
}

const mutations = {
  CLEAR_PERSONAL_DATA: state => state.personalData = new PersonalDataModel(),

  SET_AGE: (state, payload) => state.age = payload,

  CLEAR_AGE: state => state.age = null,

  SET_CID: (state, payload) => {
    state.personalData.cidId = payload.id
    state.personalData.cid = payload.cidCode
    state.personalData.cidDescription = payload.name
    return state.personalData
  },

  SET_BIRTH_DATE: (state, payload) => state.personalData.birthDate = payload,

  SET_HAS_DEFICIENCY: (state, payload) => state.personalData.hasDeficiency = payload,

  SET_DEFICIENCY_TYPE_ID: (state, payload) => {
    state.personalData.deficiencyTypeId = payload
    state.personalData.deficiencyType = state.deficiencyTypes.filter(x => x.id === payload)[0]?.name
    return state.personalData
  },

  SET_CID_CODE: (state, payload) => state.personalData.cid = payload,

  SET_CID_DESCRIPTION: (state, payload) => state.personalData.cidDescription = payload,

  SET_DEFICIENCY_TYPES: (state, payload) => state.deficiencyTypes = payload,

  CLEAR_DEFICIENCY_FIELDS: state => {
    state.personalData.cid = null
    state.personalData.cidId = null
    state.personalData.cidDescription = null
    state.personalData.deficiencyType = null
    state.personalData.deficiencyTypeId = undefined
    return state.personalData
  },

  SET_SCHOOL_TERM: (state, payload) => state.personalData.schoolTerm = payload
}

const actions = {
  setAge({ commit }, birthDate) {
    if(!birthDate) return

    const bdSplit = birthDate.split('-')
    const dob = new Date(bdSplit[0], bdSplit[1], bdSplit[2])
    const diff_ms = Date.now() - dob.getTime()
    const age_dt = new Date(diff_ms)
    commit('SET_AGE', Math.abs(age_dt.getUTCFullYear() - 1970))
    commit('modalityLocalTrainingModule/CLEAR_MODALITIES_LOCALS', null, { root: true })
  },

  clearAge({ commit }) {
    commit('CLEAR_AGE')
  },

  async fetchCid({ commit, dispatch }, cidCode) {
    dispatch('commonModule/showLoading', true, { root: true })

    await CidRepository.GetByCode(cidCode)
      .then(res => {
          if (!res.data.data) {
          dispatch('commonModule/showLoading', false, { root: true })

          dispatch('commonModule/toast', {
            type: 'warning',
            msg: 'CID inválido ou não possui registros.',
            title: 'Aviso'
          }, { root: true })
          return Promise.reject()
        }

        commit('SET_CID', res.data.data)
        dispatch('commonModule/showLoading', false, { root: true })
        return Promise.resolve()
      })
      .catch(err => {
        dispatch('commonModule/toast', {
          type: 'error',
          msg: err,
          title: 'Erro'
        }, { root: true })

        dispatch('commonModule/showLoading', false, { root: true })
        return Promise.reject()
      })
  },

  async fetchDeficiencyTypes({ state, commit, dispatch }, companyId, force) {
    if(!state.deficiencyTypes.length || force) {
      dispatch('commonModule/showLoading', true, { root: true })

      await DeficiencyTypeRepository.GetAll(companyId)
        .then(res => {
          if (!res.data.data) {
            dispatch('commonModule/showLoading', false, { root: true })

            dispatch('commonModule/toast', {
              type: 'warning',
              msg: 'Erro ao carregar os tipos de deficiência.',
              title: 'Aviso'
            }, { root: true })
            return Promise.reject()
          }

          const defTypes = res.data.data.filter(x => x.name === 'FÍSICA' ||
            x.name === 'VISUAL' || x.name === 'INTELECTUAL' || x.name === 'AUTISMO')

          commit('SET_DEFICIENCY_TYPES', defTypes)
          dispatch('commonModule/showLoading', false, { root: true })
          return Promise.resolve()
        })
        .catch(err => {
          dispatch('commonModule/toast', {
            type: 'error',
            msg: err,
            title: 'Erro'
          }, { root: true })

          dispatch('commonModule/showLoading', false, { root: true })
          return Promise.reject()
        })
    }
    return Promise.resolve()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

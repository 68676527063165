const PRESETCLASS = {
  CLASS_1: { 
    value: 1,
    text: 'Turma 1 Segunda-Feira 8:30 as 9:30',
    numberOfVacancies: 20
  },

  CLASS_2: { 
    value: 2,
    text: 'Turma 2 Segunda-Feira 10:00 as 11:00',
    numberOfVacancies: 20
  },

  CLASS_3: { 
    value: 3,
    text: 'Turma 3 Quarta-Feira 8:30 as 9:30',
    numberOfVacancies: 20
  },

  CLASS_4: { 
    value: 4,
    text: 'Turma 4 Quarta-Feira 10:00 as 11:00',
    numberOfVacancies: 20
  },

  CLASS_5: { 
    value: 5,
    text: 'Turma 5 Sexta-Feira 8:30 as 9:30',
    numberOfVacancies: 20
  },

  CLASS_6: { 
    value: 6,
    text: 'Turma 6 Sexta-Feira 10:00 as 11:00',
    numberOfVacancies: 20
  },

  CLASS_7: { 
    value: 7,
    text: 'Turma 7 Segunda-Feira 14:00 as 15:00',
    numberOfVacancies: 20
  },

  CLASS_8: { 
    value: 8,
    text: 'Turma 8 Segunda-Feira 15:30 as 16:30',
    numberOfVacancies: 20
  },

  CLASS_9: { 
    value: 9,
    text: 'Turma 9 Quarta-Feira 14:00 as 15:00',
    numberOfVacancies: 20
  },

  CLASS_10: { 
    value: 10,
    text: 'Turma 10 Quarta-Feira 15:30 as 16:30',
    numberOfVacancies: 20
  },

  CLASS_11: { 
    value: 11,
    text: 'Turma 11 Sexta-Feira 14:00 as 15:00',
    numberOfVacancies: 20
  },

  CLASS_12: { 
    value: 12,
    text: 'Turma 12 Sexta-Feira 15:30 as 16:30',
    numberOfVacancies: 20
  },

  CLASS_13: { 
    value: 13,
    text: 'Turma 13 Terça-Feira 8:00 as 9:30',
    numberOfVacancies: 30
  },

  CLASS_14: { 
    value: 14,
    text: 'Turma 14 Terça-Feira 10:00 as 11:30',
    numberOfVacancies: 30
  },

  CLASS_15: { 
    value: 15,
    text: 'Turma 15 Quinta-Feira 8:00 as 9:30',
    numberOfVacancies: 30
  },

  CLASS_16: { 
    value: 16,
    text: 'Turma 16 Quinta-Feira 10:00 as 11:30',
    numberOfVacancies: 30
  },

  CLASS_17: { 
    value: 17,
    text: 'Turma 17 Terça-Feira 13:30 as 15:00',
    numberOfVacancies: 30
  },

  CLASS_18: { 
    value: 18,
    text: 'Turma 18 Terça-Feira 15:30 as 17:00',
    numberOfVacancies: 30
  },

  CLASS_19: { 
    value: 19,
    text: 'Turma 19 Quinta-Feira 13:30 as 15:00',
    numberOfVacancies: 30
  },

  CLASS_20: { 
    value: 20,
    text: 'Turma 20 Quinta-Feira 15:30 as 17:00',
    numberOfVacancies: 30
  }
}

Object.freeze(PRESETCLASS)

export default PRESETCLASS

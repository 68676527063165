export default class PersonalData {
  constructor({
    name,
    gender,
    rg,
    cpf,
    birthDate,
    schoolTerm,
    hasDeficiency,
    deficiencyTypeId,
    deficiencyType,
    cidId,
    cid,
    cidDescription,
    observations
  } = {}) {
    this.name = name
    this.gender = gender
    this.rg = rg
    this.cpf = cpf
    this.birthDate = birthDate
    this.schoolTerm = schoolTerm
    this.hasDeficiency = hasDeficiency
    this.deficiencyTypeId = deficiencyTypeId
    this.deficiencyType = deficiencyType
    this.cidId = cidId
    this.cid = cid
    this.cidDescription = cidDescription
    this.observations = observations
  }
}

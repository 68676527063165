<template>
  <b-container>
    <router-link class="btn-center mt-5" :to="{ name: 'Dashboard' }">
      <b-button variant="primary" pill>VOLTAR PARA INÍCIO</b-button>
    </router-link>
  </b-container>
</template>

<script>
export default {
  name: 'BackToDashboardButton',
}
</script>

<style>

</style>

<template>
  <b-container>
    <b-card body-class="bg-card" class="card-border mt-5">
      <PageTitle :title="title" />

      <div class="d-flex justify-content-center">
        <p class="not-found">404</p>
      </div>
      <router-link class="btn-center" :to="{ name: 'Dashboard' }">
        <b-button variant="primary" pill>VOLTAR PARA INÍCIO</b-button>
      </router-link>
    </b-card>
  </b-container>
</template>

<script>
import PageTitle from '@/components/pageTitle/PageTitle.vue'
import BackToDashboardButton from '@/components/backToDashboardButton/backToDashboardButton.vue'

export default {
  name: '404',
  components: {
    [PageTitle.name]: PageTitle,
    [BackToDashboardButton.name]: BackToDashboardButton
  },

  data: () => ({
    title: 'Página não encontrada!'
  })
}
</script>

<style>
  .not-found {
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
  }
</style>

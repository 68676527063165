import SocialProjectManager from '../../clients/SocialProjectManager'

const resourceName = 'deficiencytype'

const GetAll = companyId => SocialProjectManager.get(`${resourceName}/${companyId}`)

const DeficiencyTypeRepository = {
  GetAll
}

export default DeficiencyTypeRepository

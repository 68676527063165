import SocialProjectManager from '../../clients/SocialProjectManager'

const resourceName = 'contacttype'

const GetAll = companyId => SocialProjectManager.get(`${resourceName}/${companyId}`)

const ContactTypeRepository = {
  GetAll
}

export default ContactTypeRepository

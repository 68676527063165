export default class ModalityLocalTraining {
  constructor({
    localTrainingId,
    localTrainingName,
    modalityId,
    modalityName,
    presetClass
  } = {}) {
    this.localTrainingId = localTrainingId
    this.localTrainingName = localTrainingName
    this.modalityId = modalityId
    this.modalityName = modalityName
    this.presetClass = presetClass
  }
}

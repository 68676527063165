import SocialProjectManager from '../../clients/SocialProjectManager'

const resourceName = 'modalitylocaltraining'

const GetAll = () => SocialProjectManager.get(resourceName)
const CheckVacancies = presetClass => SocialProjectManager.put(`${resourceName}/check-vacancie`, presetClass)

const ModalityLocalTrainingRepository = {
  GetAll,
  CheckVacancies
}

export default ModalityLocalTrainingRepository
